import React, { useEffect, useRef, useState } from "react";
import { gsap } from "gsap";
import "./header.css";

export const Header = ({ data }) => {
  const brandRef = useRef(null);
  const titleRef = useRef(null);
  const textRef = useRef(null);
  const ctaRef = useRef(null);
  const canvasRef = useRef(null);
  const intervalRef = useRef(null);
  const [isScrolled, setIsScrolled] = useState(false);

  // Enhanced words and colors for the tech industry
  const words = [
    "Innovation",
    "Excellence",
    "Solutions",
    "Technology",
    "Future"
  ];
  const colors = [
    "#0066cc",  // Professional blue
    "#2ecc71",  // Modern green
    "#3498db",  // Soft blue
    "#9b59b6",  // Purple for innovation
    "#1abc9c"   // Teal for technology
  ];

  useEffect(() => {
    // Canvas setup
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");

    const setCanvasSize = () => {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
    };
    setCanvasSize();

    // Enhanced Particle class with connection lines
    class Particle {
      constructor() {
        this.x = Math.random() * canvas.width;
        this.y = Math.random() * canvas.height;
        this.size = Math.random() * 3 + 1;
        this.speedX = Math.random() * 3 - 1.5;
        this.speedY = Math.random() * 3 - 1.5;
        this.color = `rgba(66, 133, 244, ${Math.random() * 0.5 + 0.2})`;
      }

      update() {
        this.x += this.speedX;
        this.y += this.speedY;

        // Bounce off edges
        if (this.x > canvas.width || this.x < 0) this.speedX *= -1;
        if (this.y > canvas.height || this.y < 0) this.speedY *= -1;
      }

      draw() {
        ctx.fillStyle = this.color;
        ctx.beginPath();
        ctx.arc(this.x, this.y, this.size, 0, Math.PI * 2);
        ctx.fill();
      }
    }

    const particles = [];
    const particleCount = 150; // Increased particle count

    const initParticles = () => {
      particles.length = 0;
      for (let i = 0; i < particleCount; i++) {
        particles.push(new Particle());
      }
    };

    const connectParticles = () => {
      const maxDistance = 100;
      for (let i = 0; i < particles.length; i++) {
        for (let j = i; j < particles.length; j++) {
          const dx = particles[i].x - particles[j].x;
          const dy = particles[i].y - particles[j].y;
          const distance = Math.sqrt(dx * dx + dy * dy);

          if (distance < maxDistance) {
            ctx.beginPath();
            ctx.strokeStyle = `rgba(66, 133, 244, ${0.2 * (1 - distance/maxDistance)})`;
            ctx.lineWidth = 1;
            ctx.moveTo(particles[i].x, particles[i].y);
            ctx.lineTo(particles[j].x, particles[j].y);
            ctx.stroke();
          }
        }
      }
    };

    const animateParticles = () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      particles.forEach((particle) => {
        particle.update();
        particle.draw();
      });
      connectParticles();
      requestAnimationFrame(animateParticles);
    };

    initParticles();
    animateParticles();

    // Handle window resize
    const handleResize = () => {
      setCanvasSize();
      initParticles();
    };
    window.addEventListener("resize", handleResize);

    // Enhanced text rotation with GSAP
    const rotatingText = document.querySelector(".header-rotating-text");
    let currentIndex = 0;
    intervalRef.current = setInterval(() => {
      gsap.to(rotatingText, {
        y: 20,
        opacity: 0,
        duration: 0.5,
        ease: "power2.inOut",
        onComplete: () => {
          rotatingText.textContent = words[currentIndex];
          rotatingText.style.color = colors[currentIndex];
          gsap.to(rotatingText, {
            y: 0,
            opacity: 1,
            duration: 0.5,
            ease: "power2.out"
          });
          currentIndex = (currentIndex + 1) % words.length;
        }
      });
    }, 3000);

    // Scroll effect
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 50);
    };
    window.addEventListener("scroll", handleScroll);

    // Initial animations
    gsap.from(brandRef.current, {
      y: -50,
      opacity: 0,
      duration: 1,
      ease: "power3.out"
    });
    gsap.from(titleRef.current, {
      y: 50,
      opacity: 0,
      duration: 1,
      delay: 0.3,
      ease: "power3.out"
    });
    gsap.from(textRef.current, {
      y: 50,
      opacity: 0,
      duration: 1,
      delay: 0.6,
      ease: "power3.out"
    });
    gsap.from(ctaRef.current, {
      y: 50,
      opacity: 0,
      duration: 1,
      delay: 0.9,
      ease: "power3.out"
    });

    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("scroll", handleScroll);
      clearInterval(intervalRef.current);
    };
  }, []);

  const handleGetStarted = () => {
    const featuresSection = document.getElementById("features");
    if (featuresSection) {
      featuresSection.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <header className={`header-hero ${isScrolled ? 'scrolled' : ''}`}>
      <canvas className="header-canvas" ref={canvasRef}></canvas>
      <div className="header-overlay"></div>
      <div className="header-content">
        <div className="header-brand" ref={brandRef}>
          <span className="header-brand-text">TERRY</span>
          <span className="header-brand-highlight">TECHNOLOGIES</span>
        </div>
        <h1 className="header-title" ref={titleRef}>
        Pioneering Digital          <span className="header-rotating-text">Innovation</span>
        </h1>
        <p className="header-description" ref={textRef}>
          {data?.description ||
            " Empowering businesses through cutting-edge IT solutions. Our expert team delivers transformative digital experiences, from custom software development to comprehensive enterprise solutions, driving your success in the digital age."}
        </p>
        <div className="header-cta-container" ref={ctaRef}>
          <button className="header-cta-primary" onClick={handleGetStarted}>
            Start Your Journey
          </button>
          <button className="header-cta-secondary">
            More
          </button>
        </div>
      </div>
    </header>
  );
};

export default Header;