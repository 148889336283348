import React, { useState, useEffect } from 'react';
import { Link, NavLink } from 'react-router-dom';
import './navigation.css'; // Import the CSS file

export const Navigation = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  const handleToggle = () => setIsOpen(!isOpen);
  const handleLinkClick = () => setIsOpen(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      if (scrollTop > 50) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <nav className={`navbar ${isScrolled ? 'navbar-scrolled' : ''}`}>
      <div className="navbar-container">
        <Link to="/" className="navbar-logo" onClick={handleLinkClick}>
          <img src="/img/logo/logo.png" alt="Logo" />
        </Link>

        <button
          className={`navbar-toggle ${isOpen ? 'open' : ''}`}
          onClick={handleToggle}
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggle-icon"></span>
        </button>

        <div className={`navbar-menu ${isOpen ? 'open' : ''}`}>
          <ul className="navbar-nav">
            <li className="nav-item">
              <NavLink to="/" exact activeClassName="active" onClick={handleLinkClick}>
                Home
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/services" activeClassName="active" onClick={handleLinkClick}>
                Services
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/whoweare" activeClassName="active" onClick={handleLinkClick}>
                About Us
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/careers" activeClassName="active" onClick={handleLinkClick}>
                Careers
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/contact" activeClassName="active" onClick={handleLinkClick}>
                Contact
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navigation;