// Loader.jsx
import React, { useState, useEffect, useRef } from 'react';
import './Loader.css';

// Particle class definition outside of component to avoid recreation
class Particle {
  constructor(canvas) {
    this.canvas = canvas;
    this.reset();
  }

  reset() {
    this.x = Math.random() * this.canvas.width;
    this.y = Math.random() * this.canvas.height;
    this.size = Math.random() * 3 + 1;
    this.speedX = Math.random() * 2 - 1;
    this.speedY = Math.random() * 2 - 1;
    this.opacity = Math.random() * 0.5 + 0.2;
  }

  update() {
    this.x += this.speedX;
    this.y += this.speedY;
    if (this.x < 0 || this.x > this.canvas.width || this.y < 0 || this.y > this.canvas.height) {
      this.reset();
    }
  }

  draw(ctx) {
    ctx.beginPath();
    ctx.arc(this.x, this.y, this.size, 0, Math.PI * 2);
    ctx.fillStyle = `rgba(255, 255, 255, ${this.opacity})`;
    ctx.fill();
  }
}

export const Loader = ({
  initialProgress = 0,
  finalProgress = 100,
  duration = 2000,
  color = '#6d28d9',
  showParticles = true
}) => {
  const [progress, setProgress] = useState(initialProgress);
  const [isComplete, setIsComplete] = useState(false);
  const particlesRef = useRef(null);
  const animationFrameRef = useRef(null);
  const circumference = 2 * Math.PI * 120;

  // Progress animation logic
  useEffect(() => {
    const startTime = Date.now();
    
    const animate = () => {
      const elapsed = Date.now() - startTime;
      const nextProgress = Math.min(
        initialProgress + ((finalProgress - initialProgress) * elapsed) / duration,
        finalProgress
      );

      setProgress(Math.round(nextProgress));

      if (nextProgress < finalProgress) {
        animationFrameRef.current = requestAnimationFrame(animate);
      } else {
        setIsComplete(true);
      }
    };

    animationFrameRef.current = requestAnimationFrame(animate);
    return () => cancelAnimationFrame(animationFrameRef.current);
  }, [initialProgress, finalProgress, duration]);

  // Particle effect logic
  useEffect(() => {
    if (!showParticles || !particlesRef.current) return;

    const canvas = particlesRef.current;
    const ctx = canvas.getContext('2d');
    const particles = Array.from({ length: 50 }, () => new Particle(canvas));
    let animationId;

    const handleResize = () => {
      canvas.width = canvas.offsetWidth;
      canvas.height = canvas.offsetHeight;
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    const animate = () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      particles.forEach(particle => {
        particle.update();
        particle.draw(ctx);
      });
      animationId = requestAnimationFrame(animate);
    };

    animate();
    return () => {
      window.removeEventListener('resize', handleResize);
      cancelAnimationFrame(animationId);
    };
  }, [showParticles]);

  const strokeOffset = circumference - (progress / 100) * circumference;

  return (
    <div className="modern-loader">
      {showParticles && <canvas ref={particlesRef} className="particles-canvas" />}
      
      <div className="loader-content">
        <svg className="progress-ring" width="260" height="260">
          <circle
            className="progress-ring__circle-bg"
            stroke="#1a1a1a"
            strokeWidth="8"
            fill="transparent"
            r="120"
            cx="130"
            cy="130"
          />
          <circle
            className="progress-ring__circle"
            stroke={color}
            strokeWidth="8"
            fill="transparent"
            r="120"
            cx="130"
            cy="130"
            style={{
              strokeDasharray: circumference,
              strokeDashoffset: strokeOffset,
            }}
          />
        </svg>
        
        <div className="progress-content">
          <div className="progress-number">
            <span className="number">{progress}</span>
            <span className="percent">%</span>
          </div>
          {isComplete && <div className="completion-message">Loading Complete!</div>}
        </div>
      </div>
    </div>
  );
};