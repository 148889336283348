import React from "react";
import './footer.css'; // Ensure this is the correct path
import { FaFacebookF, FaTwitter, FaLinkedinIn, FaInstagram } from 'react-icons/fa';

export const Footer = () => {
  return (
    <footer className="footer">
      <div className="container">
        <div className="footer-content">
          <div className="footer-section">
            <img 
             src="/img/logo/logo.png"
              alt="Terry Technologies Logo" 
              className="footer-logo"
            />
            <p>
              Terry Technologies Limited is a leading provider of Information and Communication Technology (ICT) solutions in Uganda.
            </p>
          </div>
          <div className="footer-section">
            <h4>Quick Links</h4>
            <ul>
              <li><a href="whoweare">About Us</a></li>
              <li><a href="services">Services</a></li>
              <li><a href="contact">Contact</a></li>
              <li><a href="#privacy">Privacy Policy</a></li>
              <li><a href="#terms">Terms of Service</a></li>
            </ul>
          </div>
          <div className="footer-section">
            <h4>Contact Us</h4>
            <ul>
              <li>Email: info@terrytechnologie.com</li>
              <li>Phone: +256766427210</li>
              <li>ACACIA MALL 4th Floor<br />KICEMENT-KAMPALA, UGANDA</li>
            </ul>
          </div>
          <div className="footer-section">
            <h4>Follow Us</h4>
            <ul className="social-icons">
              <li><a href="#facebook" aria-label="Facebook"><FaFacebookF /></a></li>
              <li><a href="https://x.com/TTechnologieso?t=WsKCYuLx99FKU3eF79AvOA&s=09" aria-label="Twitter"><FaTwitter /></a></li>
              <li><a href="#linkedin" aria-label="LinkedIn"><FaLinkedinIn /></a></li>
              <li><a href="#instagram" aria-label="Instagram"><FaInstagram /></a></li>
            </ul>
          </div>
        </div>
        <div className="footer-bottom">
          <p>© 2025 TERRY TECHNOLOGIES. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
};
