import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import './SpecializedTech.css';

export const SpecializedTech = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
      mirror: false,
      offset: 100
    });
  }, []);

  const techFields = [
    {
      imgSrc: "img/fintech.jpg",
      title: "FinTech",
      description: "Financial technology innovations for a new age of banking.",
      icon: "💰",
      stats: { growth: "25%", companies: "2000+" },
      aosEffect: "fade-up"
    },
    {
      imgSrc: "img/edtech.jpg",
      title: "EdTech",
      description: "Transforming education with digital tools.",
      icon: "📚",
      stats: { growth: "32%", companies: "1500+" },
      aosEffect: "fade-up"
    },
    {
      imgSrc: "img/medtech.jpg",
      title: "MedTech",
      description: "Advancing healthcare through innovative technology.",
      icon: "🏥",
      stats: { growth: "28%", companies: "1800+" },
      aosEffect: "fade-up"
    },
    {
      imgSrc: "img/agritech.jpg",
      title: "AgriTech",
      description: "Revolutionizing agriculture with technology.",
      icon: "🌾",
      stats: { growth: "20%", companies: "1200+" },
      aosEffect: "fade-up"
    },
    {
      imgSrc: "img/insuretech.jpg",
      title: "InsureTech",
      description: "Innovative solutions for the insurance industry.",
      icon: "🛡️",
      stats: { growth: "22%", companies: "900+" },
      aosEffect: "fade-up"
    },
    {
      imgSrc: "img/energytech.jpg",
      title: "EnergyTech",
      description: "Sustainable energy solutions for a greener future.",
      icon: "⚡",
      stats: { growth: "30%", companies: "1600+" },
      aosEffect: "fade-up"
    },
    {
      imgSrc: "img/esg.jpg",
      title: "ESG",
      description: "Environmental, Social, and Governance practices.",
      icon: "🌍",
      stats: { growth: "35%", companies: "2500+" },
      aosEffect: "fade-up"
    },
    {
      imgSrc: "img/projectmgmt.jpg",
      title: "Project Management",
      description: "Effective management of projects and teams.",
      icon: "📊",
      stats: { growth: "18%", companies: "3000+" },
      aosEffect: "fade-up"
    }
  ];

  const TechCard = ({ imgSrc, title, description, icon, stats, aosEffect, index }) => (
    <div 
      className="tech-card"
      data-aos={aosEffect}
      data-aos-delay={index * 100}
    >
      <div className="card-icon">{icon}</div>
      <div className="card-image-container">
        <img src={imgSrc} alt={title} className="card-image" loading="lazy" />
        <div className="card-overlay">
          <div className="stats-container">
            <div className="stat">
              <span className="stat-label">Growth</span>
              <div className="stat-bar">
                <div 
                  className="stat-fill"
                  style={{ width: stats.growth }}
                ></div>
              </div>
              <span className="stat-value">{stats.growth}</span>
            </div>
            <div className="stat">
              <span className="stat-label">Companies</span>
              <span className="stat-value">{stats.companies}</span>
            </div>
          </div>
        </div>
      </div>
      <h3 className="card-title">{title}</h3>
      <p className="card-description">{description}</p>
    </div>
  );

  return (
    <div className="specialized-tech-section">
      <h1 
        className="section-title"
        data-aos="fade-down"
      >
        Our Specialized Tech Fields
      </h1>
      <div className="tech-grid">
        {techFields.map((field, index) => (
          <TechCard
            key={index}
            {...field}
            index={index}
          />
        ))}
      </div>
    </div>
  );
};

export default SpecializedTech;