import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import "./home1.css";

// Register GSAP plugins
gsap.registerPlugin(ScrollTrigger);

// Styled Components
const Section = styled.section`
  position: relative;
  min-height: 100vh;
  overflow: hidden;
  background: #000;
  color: #fff;
`;

const VideoBackground = styled.video`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0.7;
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    135deg,
    rgba(0, 82, 133, 0.9) 0%,
    rgba(0, 41, 66, 0.95) 100%
  );
`;

const ContentWrapper = styled.div`
  position: relative;
  z-index: 2;
  max-width: 1200px;
  margin: 0 auto;
  padding: 4rem 2rem;
  opacity: 0;
`;

const MainHeading = styled.h1`
  font-size: clamp(2rem, 5vw, 4rem);
  font-weight: 800;
  background: linear-gradient(135deg, #fff 0%, #a5d8ff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 2rem;
  text-align: center;
  opacity: 0;
  transform: translateY(20px);
`;

const SubHeading = styled.p`
  font-size: clamp(1.1rem, 2vw, 1.5rem);
  color: #a5d8ff;
  text-align: center;
  margin-bottom: 4rem;
  opacity: 0;
  transform: translateY(20px);
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  margin-top: 3rem;
`;

const Card = styled.div`
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  border-radius: 20px;
  padding: 2rem;
  transition: all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  border: 1px solid rgba(255, 255, 255, 0.1);
  opacity: 0;
  transform: translateY(30px);

  &:hover {
    transform: translateY(-10px);
    background: rgba(255, 255, 255, 0.15);
    border: 1px solid rgba(255, 255, 255, 0.2);
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
  }
`;

const CardTitle = styled.h3`
  color: #0d6efd;
  margin-bottom: 1.5rem;
  font-size: 1.5rem;
`;

const List = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

const ListItem = styled.li`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  font-size: 1.1rem;
  color: #fff;
  opacity: 0;
  transform: translateX(-20px);

  &:before {
    content: "→";
    margin-right: 1rem;
    color: #0d6efd;
  }
`;

export const Home1 = () => {
  const [isLoaded, setIsLoaded] = useState(false);
  const contentRef = useRef(null);
  const headingRef = useRef(null);
  const subHeadingRef = useRef(null);
  const cardsRef = useRef([]);
  const listItemsRef = useRef([]);

  useEffect(() => {
    if (isLoaded) {
      // Initialize animations
      const tl = gsap.timeline({ defaults: { ease: "power3.out" } });

      // Main content fade in
      tl.to(contentRef.current, {
        opacity: 1,
        duration: 1
      });

      // Heading animations
      tl.to(headingRef.current, {
        opacity: 1,
        y: 0,
        duration: 1
      }, "-=0.5");

      tl.to(subHeadingRef.current, {
        opacity: 1,
        y: 0,
        duration: 1
      }, "-=0.7");

      // Cards animation
      cardsRef.current.forEach((card, index) => {
        gsap.to(card, {
          scrollTrigger: {
            trigger: card,
            start: "top bottom-=100",
            toggleActions: "play none none reverse"
          },
          opacity: 1,
          y: 0,
          duration: 0.8,
          delay: index * 0.2
        });
      });

      // List items animation
      listItemsRef.current.forEach((item, index) => {
        gsap.to(item, {
          scrollTrigger: {
            trigger: item,
            start: "top bottom-=50",
            toggleActions: "play none none reverse"
          },
          opacity: 1,
          x: 0,
          duration: 0.5,
          delay: index * 0.1
        });
      });
    }
  }, [isLoaded]);

  const expertiseItems = [
    "Advanced Analytics",
    "Cloud Solutions",
    "Cybersecurity",
    "Digital Strategy",
    "Enterprise Architecture",
    "Mobile App Development"
  ];

  const whyChooseUsItems = [
    "Proven Track Record",
    "Innovative Solutions",
    "Customer-Centric Approach",
    "Experienced Team"
  ];

  return (
    <Section>
      <VideoBackground
        autoPlay
        muted
        loop
        playsInline
        onLoadedData={() => setIsLoaded(true)}
      >
        <source src="/vid/2.mp4" type="video/mp4" />
      </VideoBackground>
      <Overlay />
      
      <ContentWrapper ref={contentRef}>
        <MainHeading ref={headingRef}>
          Digital Transformation of Business
        </MainHeading>

        <SubHeading ref={subHeadingRef}>
          Transforming businesses through innovative digital solutions
        </SubHeading>

        <Grid>
          <Card ref={el => cardsRef.current[0] = el}>
            <CardTitle>Our Expertise</CardTitle>
            <List>
              {expertiseItems.map((item, index) => (
                <ListItem
                  key={`expertise-${index}`}
                  ref={el => listItemsRef.current[index] = el}
                >
                  {item}
                </ListItem>
              ))}
            </List>
          </Card>

          <Card ref={el => cardsRef.current[1] = el}>
            <CardTitle>Why Choose Us?</CardTitle>
            <List>
              {whyChooseUsItems.map((item, index) => (
                <ListItem
                  key={`why-choose-${index}`}
                  ref={el => listItemsRef.current[index + expertiseItems.length] = el}
                >
                  {item}
                </ListItem>
              ))}
            </List>
          </Card>
        </Grid>
      </ContentWrapper>
    </Section>
  );
};